.dialog-zegel {
  .p-dialog-header {
    border-radius: 0px !important;
  }
  .p-dialog-content {
    //display: flex !important;
    border-radius: 0px !important;
    text-align: center;
    //justify-content: center !important;
  }
}
.dialog-image {
  .p-dialog-header {
    padding: 0 !important;
    background: transparent !important;
    border: none !important;
    .p-dialog-header-icon {
      color: #fff !important;
    }
  }
  .p-dialog-content {
    background: transparent !important;
  }

  box-shadow: none !important;
}

@media screen and (max-width: 767px) {
  .p-dialog-mask {
    height: 100vh !important;
    z-index: 1000000 !important;
  }
  .dialog-zegel {
    width: 100vw !important;
    height: 100vh !important;
    max-height: 100vh !important;
    background-color: #fff;
  }

  .p-dialog-content {
    display: flex !important;
    align-items: center !important;
  }
  .p-dialog-footer {
    display: flex !important;
    flex-direction: column !important;
    gap: 20px;
  }
}
