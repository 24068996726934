.button-zegel {
  border-radius: 0 !important;
  border: none !important;
}
.button-zegel.primary {
  background-color: #404bd9;
  color: #fff;
  font-size: 14px !important;
  line-height: 19px !important;
  height: 50px !important;
}
.button-zegel.primary:hover {
  background-color: #343cb3 !important;
}

.button-zegel.secondary {
  background-color: #efefef;
  color: #222;
  font-size: 14px !important;
  line-height: 19px !important;
  height: 50px !important;
}
.button-zegel.secondary:hover {
  background-color: #d9d9d9 !important;
  color: #222 !important;
}

.button-zegel.primary-2 {
  background-color: #ffc400;
  color: #222;
}
.button-zegel.primary-2:hover {
  background-color: #f2ba00 !important;
  color: #222 !important;
}

.button-zegel.terceary {
  background-color: #ffffff;
  color: #222;
  border: 1px solid #222222 !important; 
}
.button-zegel.terceary:hover {
  background-color: #222222 !important;
  color: #ffffff !important;
}

.button-zegel.Quaternary {
  background-color: #585858;
  color: #222;
  border: 1px solid #222222;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.button-zegel.Quaternary:hover {
  outline: none;
  background-color: #131313;
}

.button-zegel.Black {
  background-color: #010000;
  color: #000000;
  border: 1px solid #000000;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.button-zegel.Black:hover {
  outline: none;
  background-color: #131313;
}
