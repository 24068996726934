.input-zegel {
  border-color: #c4c4c4 !important;
  border: 1px solid #c4c4c4 !important;
  //border-style: solid !important;
  border-radius: 0px !important;
  background: #ffffff !important;
  //height: 40px !important;
  .p-password-input {
    width: 100%;
    border: none !important;
    border-radius: 0 !important;
  }
  .password {
    border-radius: none !important;
  }
}

.input-zegel .password:hover {
  border-radius: 0px !important;
}
