.zal-login
{
    width: 100vw;
    //gap: 8px;
}
.login-fondo{
    overflow: hidden;
    height: 100vh;
    width: 67vw;
}
.login-form
{
    width: 33vw;
    height: 100%;
    // border-color: #000000;
    // border-width: 1px;
    
    //border-style: dashed;

    // filter: drop-shadow(0px 1px 1px rgba(0,0,0,0.16 ));

    background: #ffffff;
    .lf-tabLogin
    {
        width: 100%;
        height: 60px;
        padding: 0px 35px 0px 35px;
        // border-bottom: 1px solid rgba(0,0,0,0.16 );
        
    }
    .lf-tabLoginRecuperar
    {
        width: 10%;
        height: 60px;
        padding: 0px 35px 0px 35px;
        // border-bottom: 1px solid rgba(0,0,0,0.16 );
        
    }
    .lf-tabContentLogin
    {
        width: 100%;
        //height: 60px;
        padding: 0px 35px 0px 35px;
        // border-bottom: 1px solid rgba(0,0,0,0.16 );
        
    }
    .lf-credenciales
    {
        //height: calc(100vh - 234px);
        width: 100%;
        //padding: 0px 35px 0px 35px;
    }
    .lf-logo
    {
        
        width: 100%;
        //padding: 0px 35px 0px 35px;
    }
}


@media screen and (max-width: 767px) {
    .login-fondo{
        display: none;
    }
    .login-form
    {
        width: 100%;
    }
   .grid{
    margin-left: 0 !important;
   }
   .lf-tabLoginRecuperar
   {
    padding-left:12px !important;
    padding-right:12px !important;
   }
   .lf-tabContentLogin
   {
    padding-left:12px !important;
    padding-right:12px !important;
    width: 100vw !important;
   }
  }