/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  color: #222222;
}

/* TAB */
.p-tabview-nav-link {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
  line-height: 24px !important;
  font-style: normal !important;
  text-align: center !important;

  justify-content: center;
  color: #222222;
}

.p-tabview-selected.p-highlight {
  .p-tabview-nav-link {
    .p-tabview-title {
      font-weight: 700 !important;
      color: #222222 !important;
    }
    color: #222222 !important;
  }
}
.p-tabview-ink-bar {
  height: 5px !important;
  background: #222222 !important;
}
ol,
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
body {
  background: #efefef;
}
.link-zad {
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  font-style: normal;
  text-align: center;
  color: #222222;
  cursor: pointer;
}

.text-title-24 {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  font-style: normal;
  text-align: left;
  color: #222222;
}

.text-normal-12 {
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  font-style: normal;
  text-align: left;
  color: #222222;
}

.button-img {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  //color: var(--text-color-secondary);
  border-radius: 50%;
  border: 0 !important;
  width: 40px;
  height: 40px;
  outline: none !important;
  cursor: pointer;
  box-shadow: none !important;
}
.p-link-button {
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: 0 !important;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 50%;
  color: #000000;
  outline: none !important;
  box-shadow: none !important;
}
.p-link-button:hover {
  color: #222;
  outline: none !important;
  box-shadow: none !important;
  background: #efefef !important;
}
.p-button:active,
.p-link:active {
  outline: none !important;
  box-shadow: none !important;
}
.p-button:focus,
.p-link:focus {
  box-shadow: none !important; /* Eliminar el box-shadow en estado enfocado */
}

// .p-link-button::marker
// {
//   outline: none !important;
//   border: none !important;
// }

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #343a40;
  border-color: transparent;
  background: #e9ecef;
}

.p-dialog-header-icon {
  border: none !important;
}
a {
  text-decoration: none;
}

.dashboard-footer-curso-interno
{
  width: 100%;
  display: flex;
  margin-top: auto;
  height: 100px;
  background: #222;
  align-items: center;
  .footer-content {
    width: 100%;
    justify-content: start;
    max-width: 1190px;
    padding: 0 15px;
    margin-right: auto;
    margin-left: auto;
    // position: relative;
    // // display: grid;
    align-items: center;
  }
}

.dashboard-footer {
  width: 100%;
  display: flex;
  margin-top: auto;
  height: 100px;
  background: #222;
  align-items: center;
  .footer-content {
    width: 100%;
    justify-content: start;
    max-width: 1190px;
    padding: 0 15px;
    margin-right: auto;
    margin-left: auto;
    // position: relative;
    // // display: grid;
    align-items: center;
  }
}
.content-general,
.content-general-navigation {
  width: 100%;
  max-width: 1190px;
  padding: 0 15px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px !important;
  //margin-top: 60px;
}
.content-general {
  min-height: calc(100vh - 160px);
}
.content-general-navigation {
  min-height: calc(100vh - 208px);
  //min-height: 100%;
}
.c-tab-interna > a,
.p-tabview-nav-link:focus {
  outline: none !important;
  box-shadow: none !important;
}

.p-dialog .p-dialog-content {
  color: #222 !important;
}
.subtitleZegel {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .layout-main {
    height: 100vh;
  }
  .dashboard-footer-curso-interno
  {
    width: 100%;
    display: flex;
    margin-top: auto;
    height: 35vh;
    background: #222;
    align-items: center;
    .footer-content {
      width: 100%;
      justify-content: start;
      max-width: 1190px;
      padding: 0 15px;
      margin-right: auto;
      margin-left: auto;
      // position: relative;
      // // display: grid;
      align-items: center;
    }
  }
  .dashboard-footer  {
    width: 100%;
    display: flex;
    margin-top: auto;
    height: 12vh;
    background: #222;
    align-items: center;
    .footer-content {
      width: 100%;
      justify-content: start;
      max-width: 1190px;
      padding: 0 15px;
      margin-right: auto;
      margin-left: auto;
      // position: relative;
      // // display: grid;
      align-items: center;
    }
  }
}
